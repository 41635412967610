<template>
  <div class="sendSuccessfully-wrap">
    <img src="@/assets/images/sendSuccessfully-icon.png" />
    <h4>提交成功</h4>
    <!-- <p>成功发送200条，失败0条<br/>{{num}}秒后返回首页</p> -->
    <p>等待系统群发中。。。。。。</p>
</div>
</template>

<script>
export default{
  name:'sendSuccessfully',
  data(){
    return{
      num:3
    }
  },
  mounted() {
    // 定时返回
    let _this=this,time=setInterval(function(){
      _this.num=_this.num-1
      if(_this.num<1){
        clearInterval(time)
        _this.$router.push('index')
      }
    },1000)
  }
}
</script>

<style lang="scss" scoped>
.sendSuccessfully-wrap{
  border-top: 20px solid #F5F5F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  height: 100vh;
  img{
    margin-top: 260px;
    width: 261px;
    height: 253px;
    margin-bottom: 55px;
  }
  h4{
    font-size: 36px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
  }
  p{
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    color: #B9B9B9;
    line-height: 45px;
  }
}
</style>
